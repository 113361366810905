<template>
  <ContentWrapper>
    <div class="content-heading">
      <div>門店列表</div>
    </div>

    <div class="card card-default">
      <div class="card-body">

        <el-form ref="searchForm" :inline="true" :model="searchForm" label-width="5rem">
          <el-form-item label="門店類型" prop="type">
            <el-select v-model="searchForm.type">
              <el-option
                  v-for="type in types"
                  :key="type.value"
                  :label="type.label"
                  :value="type.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="門店名稱" prop="name">
            <el-input v-model="searchForm.name" placeholder="輸入門店名稱篩選" />
          </el-form-item>
          <el-form-item label="啟用" prop="status">
            <el-radio-group v-model="searchForm.status">
              <el-radio-button label="-1">所有</el-radio-button>
              <el-radio-button label="1">啟用</el-radio-button>
              <el-radio-button label="0">停用</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submit">篩選</el-button>
            <el-button @click="resetForm">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div class="card card-default">
      <div class="card-body">

        <div class="operators mb-3">
          <el-button type="primary" class="ml-2" :disabled="!checked.length" @click="openAll">
            全部啟用
          </el-button>
          <el-button type="primary" class="ml-2" :disabled="!checked.length" @click="closeAll">
            全部停用
          </el-button>
        </div>
        <v-client-table :data="postOfficeList" :columns="tableConfigs.columns" :options="tableConfigs.options">
          <template slot="selectable" slot-scope="props">
            <el-checkbox v-model="checked" :label="props.row.id">
              <span></span>
            </el-checkbox>
          </template>

          <template slot="type" slot-scope="props">
            {{ typesObj[props.row.type].label }}
          </template>

          <template slot="status" slot-scope="props">
            {{ props.row.status ? '是' : '否' }}
          </template>
          <template slot="operation" slot-scope="props">
            <el-button type="primary" size="small" @click="$router.push({ name: 'post-office', params: { id: props.row.id }})">
              編輯
            </el-button>
            <el-button type="info" size="small" @click="qrcodeDialog(props.row.id)">
              二維碼
            </el-button>
            <!--<el-popconfirm title="確定要刪除這個門店嗎？" @onConfirm="deleteOffice(props.row.id)">-->
              <!--<el-button-->
                <!--slot="reference"-->
                <!--type="danger"-->
                <!--size="small"-->
              <!--&gt;-->
                <!--刪除-->
              <!--</el-button>-->
            <!--</el-popconfirm>-->
          </template>
        </v-client-table>
      </div>
    </div>

    <el-dialog
      title=""
      :visible.sync="qrCodeDialogVisible"
      width="50%"
      :append-to-body="true"
    >
      <div v-if="qrCodeInfo">
        <div class="text-center">
          <img :src="qrCodeInfo.image" class="img-fluid" />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="qrCodeDialogVisible = false">關閉</el-button>
        <el-button type="info" @click="downQrcode">下載</el-button>
        <el-button type="primary" @click="printQrcode">打印(A4)</el-button>
      </span>
    </el-dialog>
  </ContentWrapper>
</template>

<script>
import Vue from 'vue';
import _ from 'lodash';
import printJS from 'print-js';
import { getLoadingInstance, showErrorMessage } from '@/helpers';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'PostOfficeList',
  data() {
    return {
      qrCodeDialogVisible: false,
      qrCodeInfo: null,
      postOfficeList: [],
      searchForm: {
        type: '',
        name: '',
        status: -1,
      },
      checked: [],
      list: [],
      tableConfigs: {
        columns: ['selectable', 'id', 'type', 'name', 'phone', 'district_name', 'address', 'remark', 'created_at', 'status', 'operation'],
        options: {
          pagination: {
            // virtual: true,
          },
          perPage: 100,
          perPageValues: [10, 50, 100, 200],
          headings: {
            selectable: '',
            id: 'ID',
            type: '类型',
            name: '門店名稱',
            phone: '聯繫電話',
            district_name: '地區',
            address: '門店地址',
            created_at: '創建時間',
            status: '是否啟用',
            operation: '操作',
            remark: '備註',
          },
          filterable: false,
          sortable: ['status'],
        },
      },
    };
  },
  mounted() {
    this.submit();
    // this.$store.dispatch('fetchOffice');
  },
  computed: {
    ...mapState({
      types: state => state.postOffice.types,
    }),
    ...mapGetters(['currentUser', 'typesObj']),
  },
  methods: {
    resetForm() {
      this.$refs.searchForm.resetFields();
      this.submit();
    },
    submit() {
      this.checked = [];
      const loading = getLoadingInstance();
      const params = {};
      _.forEach(this.searchForm, (val, key) => {
        if (val) {
          params[key] = val;
        }
      });

      this.$api.office.get(params).then(({ data: response }) => {
        this.postOfficeList = [...response.data];
      }).catch(error => showErrorMessage(error)).finally(() => {
        loading.close();
      });
    },
    deleteOffice(id) {
      this.$store.dispatch('deleteOffice', id).then(() => {
        this.postOfficeList = _.reject(this.postOfficeList, { id });
        this.$notify.success({
          title: '已刪除該門店',
        });
      });
    },
    openAll() {
      const loading = getLoadingInstance();
      this.$api.office.updateStatus({ ids: this.checked, status: 1 }).then(() => {
        this.checked.forEach((value) => {
          const postOffice = _.find(this.postOfficeList, { id: value });
          postOffice.status = 1;
        });
        this.$notify.success({
          title: '操作成功',
        });
      }).catch(error => showErrorMessage(error)).finally(() => {
        loading.close();
      });
    },
    closeAll() {
      const loading = getLoadingInstance();
      this.$api.office.updateStatus({ ids: this.checked, status: 0 }).then(() => {
        this.checked.forEach((value) => {
          const postOffice = _.find(this.postOfficeList, { id: value });
          postOffice.status = 0;
        });
        this.$notify.success({
          title: '操作成功',
        });
      }).catch(error => showErrorMessage(error)).finally(() => {
        loading.close();
      });
    },
    qrcodeDialog(id) {
      this.$api.office.qrcode(id).then(({ data: response }) => {
        this.qrCodeInfo = response;
        console.log(this.qrCodeInfo);
        this.qrCodeDialogVisible = true;
      });
    },
    downQrcode() {
      window.open(this.qrCodeInfo.download);
    },
    printQrcode() {
      Vue.axios.get(this.qrCodeInfo.print).then(({ data: response }) => {
        printJS({
          printable: response,
          type: 'raw-html',
          showModal: true,
        });
      });
    },
  },
};
</script>
