<template>
  <ContentWrapper>
    <div class="content-heading">
      <div>{{ editing ? '編輯門店' : '添加門店' }}</div>
    </div>
    <div class="card card-default">
      <div class="card-body">
        <div class="row justify-content-center">
          <div class="col-lg-6 col-md-8 col-sm-10">
            <el-form ref="form" :model="form" :rules="rules" label-width="auto">
              <el-form-item label="門店類型" prop="type">
                <el-select v-model="form.type">
                  <el-option
                    v-for="type in types"
                    :key="type.value"
                    :label="type.label"
                    :value="type.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="門店名稱" prop="name">
                <el-input v-model="form.name" placeholder="請輸入門店名稱"></el-input>
              </el-form-item>
              <el-form-item label="門店Code（大寫字母+數字）" prop="code">
                <el-input v-model="form.code" placeholder="請輸入門店Code" oninput="value=value.replace(/[^\w]/g,'');"
                          onkeyup="value = value.toUpperCase()"></el-input>
              </el-form-item>
              <el-form-item label="聯繫電話" prop="phones">
                <el-input v-model.trim="form.phones" placeholder="請輸入聯繫電話">
                  <el-select v-model="form.phone_acode" placeholder="手机区号"
                             slot="prepend" style="width: 150px">
                    <el-option v-for="item in areaCodes" :key="item.code" :value="item.value"
                               :label="item.name"></el-option>
                  </el-select>
                </el-input>
              </el-form-item>
              <el-form-item label="選擇所在地區" prop="district_id_nodes">
                <el-cascader
                  class="w-100"
                  placeholder="請選擇所在地區"
                  v-model="form.district_id_nodes"
                  :options="districts"
                  :props="districtsProps"
                  @change="getDistrictId"
                  filterable
                ></el-cascader>
              </el-form-item>
              <el-form-item label="門店地址" prop="address">
                <el-input v-model="form.address" placeholder="請輸入門店地址"></el-input>
              </el-form-item>
              <el-form-item label="是否啟用">
                <el-switch v-model="form.status"></el-switch>
              </el-form-item>
              <el-form-item label="備註">
                <el-input type="textarea" v-model="form.remark"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submit()">{{ editing ? '修改' : '立即創建' }}</el-button>
                <el-button @click="resetForm()">清空</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </ContentWrapper>
</template>

<script>
import { mapState } from 'vuex';
import { getLoadingInstance, showErrorMessage } from '../../helpers';

const formDefault = {
  type: null,
  name: '',
  code: '',
  address: '',
  phones: '',
  district_id: '',
  district_id_nodes: [],
  remark: '',
  status: true,
  phone_acode: '852',
};

export default {
  name: 'PostOfficeSave',
  data() {
    return {
      editIng: null,
      districts: [],
      districtsProps: {
        value: 'id',
        label: 'name',
        children: 'children',
      },
      areaCodes: [
        {
          name: '香港 +852',
          code: '+852',
          value: '852',
          countryCode: 'HK',
        },
        {
          name: '中國大陸 +86',
          code: '+86',
          value: '86',
          countryCode: 'CN',
        },
      ],
      form: { ...formDefault },
      rules: {
        type: { required: true, message: '請選擇門店類型', trigger: 'change' },
        name: { required: true, message: '请输入門店名称', trigger: 'blur' },
        code: [
          { required: true, message: '請輸入code', trigger: 'blur' },
        ],
        address: { required: true, message: '请输入門店地址', trigger: 'blur' },
        // phones: { required: true, message: '请输入聯繫電話', trigger: 'blur' },
        district_id_nodes: { required: true, message: '请输入所在地區', trigger: 'change' },
      },
    };
  },
  mounted() {
    this.$api.district.tree().then(({ data: response }) => {
      this.districts = response;
    });
  },
  watch: {
    '$route.params.id': {
      handler(id) {
        if (id) {
          const loading = getLoadingInstance();
          this.$api.office.getById(id).then(({ data: response }) => {
            this.form.id = response.data.id;
            this.form.name = response.data.name;
            this.form.code = response.data.code;
            this.form.type = response.data.type;
            this.form.district_id_nodes = response.data.district_id_nodes;
            this.form.district_id = response.data.district_id;
            this.form.address = response.data.address;
            this.form.status = !!response.data.status;
            const index = response.data.phone.lastIndexOf(' ');
            this.form.phone_acode = response.data.phone.substring(0, index);
            this.form.phones = response.data.phone.substring(index + 1, response.data.phone.length);
            this.form.remark = response.data.remark;
          }).catch((error) => {
            showErrorMessage(error);
            this.$router.push('/');
          }).finally(() => {
            loading.close();
          });
        } else {
          this.form = { ...formDefault };
        }
      },
      immediate: true,
    },
  },
  methods: {
    getDistrictId() {
      if (this.form.district_id_nodes.length) {
        const index = this.form.district_id_nodes.length - 1;
        this.form.district_id = this.form.district_id_nodes[index];
      }
    },
    resetForm() {
      this.$refs.form.resetFields();
    },
    submit() {
      this.$refs.form.validate((str) => {
        if (str) {
          const loading = getLoadingInstance();
          this.form.phone = `${this.form.phone_acode} ${this.form.phones}`;
          this.$api.office.save(this.form).then(() => {
            this.$notify({
              title: '成功',
              message: this.editing ? '修改成功' : '門店添加成功',
              type: 'success',
            });
            if (!this.editing) {
              this.$refs.form.resetFields();
            } else {
              this.$router.push({ name: 'post-office-list' });
            }
          }).catch(() => {
            this.$notify.error({
              title: '错误',
              message: '門店添加失败',
            });
          }).finally(() => {
            loading.close();
          });
        }
      });
    },
  },
  computed: {
    ...mapState({
      types: state => state.postOffice.types,
    }),
    editing() {
      return !!this.$route.params.id;
    },
  },
};
</script>
